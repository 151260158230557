<template>
  <div class="app-context">
    <v-container class="helpdesk-container">
      <v-row>
        <v-col cols="2"></v-col>
        <v-col cols="8" class="pl-0 pr-0">
          <v-row>
            <v-col cols="12">
              <span class="title helpdesk-title">문의사항</span>
            </v-col>
            <v-col cols="12" class="text-right">
              <p>
                <span class="helpdesk-red">*</span> 표시는 필수 입력 사항입니다.
              </p>
            </v-col>
            <v-col
              cols="12"
              style="
                border-top: 3px solid black;
                border-bottom: 3px solid black;
              "
            >
              <v-row style="border-bottom: 1px solid lightgray">
                <v-col
                  class="helpdesk-content-wrap"
                  md="4"
                  cols="12"
                  style="line-height: 70px"
                >
                  <p class="helpdesk-content">문의 제목 <span>*</span></p>
                </v-col>
                <v-col md="8" cols="12" style="line-height: 70px">
                  <input
                    type="text"
                    placeholder="제목을 입력해주세요."
                    v-model="form.title.value"
                    @keyup="checkTitle()"
                    @blur="checkTitle()"
                    class="helpdesk-input"
                  />
                  <p class="warn-message" v-if="form.title.hasError">
                    <img src="@/assets/images/icons/error_24dp.svg" />
                    <span>{{ form.title.errorTxt }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row style="border-bottom: 1px solid lightgray">
                <v-col
                  class="helpdesk-content-wrap"
                  md="4"
                  cols="12"
                  style="line-height: 180px"
                >
                  <p class="helpdesk-content">문의 내용 <span>*</span></p>
                </v-col>
                <v-col
                  md="8"
                  cols="12"
                  style="line-height: 70px; padding-top: 30px"
                >
                  <v-textarea
                    v-model="form.content.value"
                    @keyup="checkContent()"
                    @blur="checkContent()"
                    no-resize
                    outlined
                    placeholder="문의내용을 입력해주세요"
                    class="helpdesk-input"
                  />
                  <p
                    class="warn-message warn-message-content"
                    v-if="form.content.hasError"
                  >
                    <img src="@/assets/images/icons/error_24dp.svg" />
                    <span>{{ form.content.errorTxt }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row style="border-bottom: 1px solid lightgray">
                <v-col
                  class="helpdesk-content-wrap"
                  md="4"
                  cols="12"
                  style="line-height: 70px"
                >
                  <p class="helpdesk-content">문의자명 <span>*</span></p>
                </v-col>
                <v-col md="8" cols="12" style="line-height: 70px">
                  <input
                    type="text"
                    placeholder="문의자명을 입력해주세요."
                    v-model="form.name.value"
                    @keyup="checkName()"
                    @blur="checkName()"
                    class="helpdesk-input"
                  />
                  <p class="warn-message" v-if="form.name.hasError">
                    <img src="@/assets/images/icons/error_24dp.svg" />
                    <span>{{ form.name.errorTxt }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row style="border-bottom: 1px solid lightgray">
                <v-col
                  class="helpdesk-content-wrap"
                  md="4"
                  cols="12"
                  style="line-height: 70px"
                >
                  <p class="helpdesk-content">휴대폰 번호 <span>*</span></p>
                </v-col>
                <v-col md="8" cols="12" style="line-height: 50px">
                  <input
                    type="text"
                    placeholder="휴대폰 번호를 입력해주세요."
                    v-model="form.phone.value"
                    maxlength="13"
                    @keyup="checkPhone()"
                    @blur="checkPhone()"
                    class="helpdesk-input"
                  />
                  <p class="warn-message" v-if="form.phone.hasError">
                    <img src="@/assets/images/icons/error_24dp.svg" />
                    <span>{{ form.phone.errorTxt }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row style="border-bottom: 1px solid lightgray">
                <v-col
                  class="helpdesk-content-wrap"
                  md="4"
                  cols="12"
                  style="line-height: 70px"
                >
                  <p class="helpdesk-content">이메일 주소 <span>*</span></p>
                </v-col>
                <v-col md="8" cols="12" style="line-height: 50px">
                  <input
                    type="text"
                    placeholder="정확한 형식의 이메일을 입력해주세요."
                    v-model="form.email.value"
                    @keyup="checkEmail()"
                    @blur="checkEmail()"
                    class="helpdesk-input"
                  />
                  <p class="warn-message" v-if="form.email.hasError">
                    <img src="@/assets/images/icons/error_24dp.svg" />
                    <span>{{ form.email.errorTxt }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row style="border-bottom: 1px solid lightgray">
                <v-col
                  class="helpdesk-content-wrap"
                  md="4"
                  cols="12"
                  style="line-height: 70px"
                >
                  <p class="helpdesk-content">첨부파일</p>
                </v-col>
                <v-col md="8" cols="12" style="line-height: 50px">
                  <v-row>
                    <v-col md="6" cols="6">
                      <div class="a-input-wrap custom">
                        <span
                          class="file-name"
                          v-text="form.attachment.filename"
                        ></span>
                        <input
                          class="hidden file-upload"
                          type="file"
                          hidden
                          @change="onFileChange($event)"
                        />
                      </div>
                    </v-col>
                    <v-col md="6" cols="6">
                      <v-row>
                        <v-col
                          xl="6"
                          lg="6"
                          md="6"
                          sm="6"
                          xs="12"
                          class="text-center"
                        >
                          <span class="a-btn-wrap">
                            <v-btn
                              class="btn-file"
                              color="#FF7334"
                              dark
                              rounded
                              width="110"
                              large
                              elevation="0"
                              @click="attachmentFile()"
                            >
                              파일첨부
                            </v-btn>
                          </span>
                        </v-col>
                        <v-col
                          xl="6"
                          lg="6"
                          md="6"
                          sm="6"
                          xs="12"
                          class="text-center"
                        >
                          <span
                            class="a-btn-wrap delete"
                            v-if="form.attachment.filename !== ''"
                          >
                            <v-btn
                              class="btn-file"
                              color="red"
                              dark
                              rounded
                              width="110"
                              large
                              elevation="0"
                              @click="deleteFile()"
                              >삭제</v-btn
                            >
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row style="border-bottom: 1px solid lightgray">
                <v-col
                  class="helpdesk-content-wrap"
                  md="4"
                  cols="12"
                  style="line-height: 70px"
                >
                  <p class="helpdesk-content">
                    개인정보 이용 동의 <span>*</span>
                  </p>
                </v-col>
                <v-col md="8" cols="12" style="line-height: 70px">
                  <span class="agreement">
                    <span>개인정보 이용에 동의합니다.</span>
                    <span
                      class="check-btn"
                      @click="cancelAgree()"
                      :class="{ active: form.agreement.flag }"
                    >
                      <img
                        src="@/assets/images/onck.svg"
                        v-if="form.agreement.flag"
                      />
                      <img src="@/assets/images/unck.svg" v-else />
                    </span>
                    <span class="agree-btn-wrap">
                      <v-btn
                        @click="callAgreePop()"
                        class="btn-file"
                        color="#FF7334"
                        dark
                        rounded
                        width="110"
                        large
                        elevation="0"
                      >
                        내용보기
                      </v-btn>
                    </span>
                    <p class="warn-message" v-if="form.agreement.hasError">
                      {{ form.agreement.errorTxt }}
                    </p>
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="12" class="text-center">
          <v-btn
            color="#146f83"
            dark
            rounded
            width="200"
            height="60"
            large
            class="helpdesk-register-btn"
            shaped
            elevation="0"
            @click="next()"
          >
            문의등록
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <!-- <app-footer></app-footer> -->
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
    <agreement-popup
      v-if="checkAgreementPopup"
      :popupSetAgree="popupSetAgree"
      @agree-checked="receiveAgreement"
    />
  </div>
</template>

<script>
import SideMenu from "@/components/sidemenu/SideMenu";
import AgreementPopup from "@/components/modal/AgreementPopup";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  components: {
    SideMenu,
    DefaultPopup,
    AgreementPopup,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      sideSet: {
        menuName: "introduce",
        sideTitle: "소개페이지",
      },
      form: {
        title: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "문의제목을 입력해주세요.",
        },
        content: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "문의내용을 입력해주세요.",
        },
        name: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "문의자명을 입력해주세요.",
        },
        phone: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "숫자만을 입력해주세요",
        },
        email: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "이메일 형식을 확인해주세요",
        },
        attachment: {
          flag: false,
          source: null,
          url: "",
          filename: "",
        },
        agreement: {
          flag: false,
          hasError: false,
          errorTxt: "개인정보 이용동의는 필수사항입니다",
        },
      },
      popupSet: {},
      popupSetAgree: {},
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    this.$emit("set-bar", "문의사항");
  },
  computed: {
    ...mapState("basic", ["checkPopup", "checkAgreementPopup"]),
    genetateHyphen() {
      return (numData, isBirth) => {
        // console.log("가져온 번호들", numData);
        // console.log("팩스인가?", isBirth);
        const number = numData.toString().replace(/[^0-9]/g, "");
        let generatedNum = "";
        if (isBirth) {
          if (number.length < 5) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4, 2);
            generatedNum += "-";
            generatedNum += number.substr(6);
          }
          this.form.birth.value = generatedNum;
        } else {
          if (number.length < 4) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 3);
            generatedNum += "-";
            generatedNum += number.substr(6);
          } else {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 4);
            generatedNum += "-";
            generatedNum += number.substr(7);
          }
          this.form.phone.value = generatedNum;
        }
        return generatedNum;
      };
    },
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_AGREEMENT_POPUP"]),
    ...mapActions("util", ["QUESTION_SUBMIT"]),
    deleteFile() {
      this.form.attachment.flag = false;
      this.form.attachment.source = null;
      this.form.attachment.url = "";
      this.form.attachment.filename = "";
      document.querySelector("input.file-upload").value = "";
    },
    cancelAgree() {
      if (this.form.agreement.flag) {
        this.form.agreement.flag = false;
        this.form.agreement.hasError = true;
      }
    },
    onFileChange(event) {
      const getFile = event.target.files;
      const getFileUrl = URL.createObjectURL(event.target.files[0]);
      // console.log("가져올 파일명: ", getFile[0].name);
      // console.log("가져올 파일의 URL", getFileUrl);
      this.form.attachment.source = event.target.files[0];
      /** getFile */
      this.form.attachment.url = getFileUrl;
      this.form.attachment.filename = getFile[0].name;
      this.form.attachment.flag = true;
      // console.log("order", this.form.attachment.flag);
      // console.log("파일", event.target.files[0]);
      // console.log("소스", this.form.attachment.source);
    },
    attachmentFile() {
      document.querySelector("input.file-upload").click();
    },
    receiveAgreement() {
      this.form.agreement.flag = true;
      this.form.agreement.hasError = false;
    },
    callAgreePop() {
      this.SET_AGREEMENT_POPUP(true);
      this.popupSetAgree.agreeCheckNum = 1;
      this.popupSetAgree.footerLess = true;
      this.popupSetAgree.isHuge = true;
    },
    checkDelegateEtc() {},
    checkTitle() {
      this.form.title.flag = false;
      this.form.title.hasError = true;
      const Title = this.form.title.value.replace(/\s/gi, "");
      if (Title === "") {
        this.form.title.hasError = true;
        return false;
      }
      this.form.title.flag = true;
      this.form.title.hasError = false;
    },
    checkContent() {
      this.form.content.flag = false;
      this.form.content.hasError = true;
      const Content = this.form.content.value.replace(/\s/gi, "");
      if (Content === "") {
        this.form.content.hasError = true;
        return false;
      }
      this.form.content.flag = true;
      this.form.content.hasError = false;
    },
    checkName() {
      this.form.name.flag = false;
      this.form.name.hasError = true;
      const Name = this.form.name.value.replace(/\s/gi, "");
      if (Name === "") {
        this.form.name.hasError = true;
        return false;
      }
      this.form.name.flag = true;
      this.form.name.hasError = false;
    },
    checkPhone() {
      if (this.form.phone.value !== null) {
        this.form.phone.flag = false;
        this.form.phone.hasError = true;
        const phone = this.form.phone.value.replace(/\s/gi, "");
        if (phone === "") {
          this.form.phone.flag = false;
          this.form.phone.hasError = true;
          this.form.phone.errorTxt = "핸드폰번호를 입력해주세요.";
          return false;
        }
        const isPhone = /^[0-9|-]{10,13}$/;
        if (!isPhone.test(phone)) {
          this.form.phone.errorTxt = "- 와 숫자를 입력해주세요.";
          this.form.phone.flag = false;
          this.form.phone.hasError = true;
          return false;
        }
        this.form.phone.flag = true;
        this.form.phone.hasError = false;
        this.form.phone.errorTxt = "";
        return true;
      }
    },
    checkEmail() {
      if (this.form.email.value !== null) {
        this.form.email.flag = false;
        this.form.email.hasError = true;
        const email = this.form.email.value.replace(/\s/gi, "");
        if (email === "") {
          this.form.email.flag = false;
          this.form.email.hasError = true;
          this.form.email.errorTxt = "이메일을 입력해주세요.";
          return false;
        }
        const isEmail =
          /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
        if (!isEmail.test(email)) {
          this.form.email.flag = false;
          this.form.email.hasError = true;
          this.form.email.errorTxt = "이메일 형식에 맞지 않습니다.";
          return false;
        }
        this.form.email.flag = true;
        this.form.email.hasError = false;
        this.form.email.errorTxt = "";
        return true;
      }
    },

    initFlag() {},
    checkFlag() {
      if (
        !this.form.name.flag ||
        !this.form.phone.flag ||
        !this.form.email.flag ||
        !this.form.title.flag ||
        !this.form.content.flag ||
        !this.form.agreement.flag
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content =
          "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        if (!this.form.name.flag) {
          this.form.name.hasError = true;
        }
        if (!this.form.phone.flag) {
          this.form.phone.hasError = true;
        }
        if (!this.form.email.flag) {
          this.form.email.hasError = true;
        }
        if (!this.form.title.flag) {
          this.form.title.hasError = true;
        }
        if (!this.form.content.flag) {
          this.form.content.hasError = true;
        }
        if (!this.form.agreement.flag) {
          this.form.agreement.hasError = true;
        }
        return false;
      }
      return true;
    },

    async next() {
      if (this.checkFlag()) {
        const helpdesk = {
          helpNm: this.form.name.value,
          title: this.form.title.value,
          contents: this.form.content.value,
          email: this.form.email.value,
          hpNo: this.form.phone.value.replace(/-/gi, ""),
          agreeYn: this.form.agreement.flag ? "Y" : "N",
          helpType: 1,
        };
        const transData = new FormData();
        if (this.form.attachment.source !== null) {
          transData.append("helpDeskFile", this.form.attachment.source);
          helpdesk.isDeletedAttchId = 0;
        }
        // console.log("서버로 전송할 helpdesk", helpdesk);
        const transedPrParam = JSON.stringify(helpdesk);
        transData.append("helpDeskParam", transedPrParam);

        try {
          await this.QUESTION_SUBMIT(transData).then((data) => {
            if (data === null) {
              this.SET_POPUP(true);
              this.popupSet.title = "서버요청 실패";
              this.popupSet.content = "입력정보가 서버로 전송되지 못했습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              return;
            }

            this.SET_POPUP(true);
            this.popupSet.title = "문의사항 등록성공";
            this.popupSet.content = "문의사항이 성공적으로 등록되었습니다.";
            this.popupSet.popType = "custom";
            this.popupSet.cancelBtnText = undefined;
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.nextLink = "/";
            this.popupSet.destination = true;
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "입력정보가 서버로 전송되지 못했습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content =
          "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
  },
  destroyed() {
    this.SET_POPUP(false);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/join";
@import "../../assets/scss/Helpdesk.scss";
input,
textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
</style>
